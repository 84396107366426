// import React, { useState } from 'react'
// import { useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// import { useDispatch, useSelector } from 'react-redux';
// import { AddEditDevice, updateDeviceVerifiedState } from '../appActions';
// import cross_logo from '../Assets/Icons/cross.png'
// import configVariables from '../util/constants';
// import storageService from '../util/storageService';
// import DeviceVerification from './DeviceVerification';

// function AddDeviceForm({setCloseModal, business_det = [], mode, editObject}) {

//   const { register, handleSubmit, setValue } = useForm({defaultValues:editObject});
//   const [projectOption, setProjectOption] = useState({})
//   const dispatch = useDispatch()
//   const state = useSelector((state) => state);
//   const [formErrors, setFormErrors] = useState('')

//   useEffect(() => {
//     if(mode === 'edit'){
//       let biz = getObjectById(business_det,editObject.business_id)
//       setProjectOption(biz)
//     }
//     // eslint-disable-next-line
//   }, [])
  

//   const handleBusinessSelect = (e)=>{
//     if(e.target.value !== 'null'){
//       let biz = getObjectById(business_det,e.target.value)
//       setProjectOption(biz)
//       setValue('address',biz.address)
//       setValue('email',biz.email)
//     }
//   }

//   const getObjectById = (arr,id)=>{
//     for(let i =0;i<arr.length;i++){
//       if(arr[i]._id === id){
//         return arr[i];
//       }
//     }
//   }

//   const onSubmit = (data)=>{
//     if(data.business_id !== 'null' && data.project_id !== 'null'){
//       if(mode !== 'edit'){
//         dispatch(updateDeviceVerifiedState(false))
//       }
//       data.created_by = storageService.get(configVariables.USER_ID)
//       data.ids = mode === 'edit' ? [editObject._id] : state.device_det.map(device=>{return device._id})
//       if(mode === 'edit' && data.project_id !== editObject.project_id){
//         data.old_project_id = editObject.project_id
//       }
//       dispatch(AddEditDevice(data))
//       setCloseModal(false)
//     }else{
//       setFormErrors("Select a business/project")
//     }
//   }

//   return (
//     <div className='modal'>
//       <form action="" >
//         <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
//         {formErrors && <p>{formErrors}</p>}
//         <label htmlFor="" className='form-label'>
//           Select Business
//           {mode === 'edit' ? 
//           <select >
//             <option value={projectOption?._id} >{projectOption?.name}</option>
//           </select>  
//           :
//           <select {...register('business_id',{ required: true })} onChange={handleBusinessSelect}>
//             <option value={"null"} >Select</option>
//             {business_det.map(business=> <option value={business?._id}>{business?.name}</option>)}
//           </select>  
//           }
//         </label>
//         <label htmlFor="" className='form-label'>
//           Select Project
//           <select {...register('project_id',{ required: true })}  >
//             <option value={"null"}>Select</option>
//             {projectOption?.project_id?.map(project=> <option value={project?._id} selected={editObject?.project_id === project?._id?true:false}>{project?.name}</option>)}
//           </select>
//         </label>
//         {mode !== 'edit' && Object.keys(projectOption).length > 0 && 
//           <DeviceVerification isMulti setFormErrors={setFormErrors} biz_phone={projectOption.phone} />
//         }
//         {(state.isDeviceVerified || mode === 'edit') && 
//         <>
//           <label className='form-label' htmlFor="">
//             Device Name
//             <input type="text" {...register('name',{ required: true, maxLength: 20 })}/>
//           </label>

//           {/* <label className='form-label' htmlFor="">
//             Address
//             {mode === 'edit'?
//               <textarea {...register('address', { required: true })} cols="30" rows="10"></textarea>  
//             :
//               <textarea {...register('address', { required: true })} readOnly cols="30" rows="10"></textarea>
//             }
//           </label>

//           <label className='form-label' htmlFor="">
//             Email
//             {mode === 'edit'?
//             <input type="email" {...register('email',{ required: true })} /> 
//             :
//             <input type="email" {...register('email',{ required: true })} readOnly/>
//             }
            
//           </label> */}
          
//           <div className="form-but-group">
//             <button className='form-but-1' onClick={handleSubmit(onSubmit)} type="button">
//             {
//             mode === 'edit'?
//               'Save'
//             :
//               'Add'
//             }
//             </button>
//           </div>
//         </>
//         }
//         {/* {mode === 'edit' && <button onClick={()=>{deleteBusiness(edit_project_det._id)}} type='button'>Delete</button>}  */}
//       </form>
//     </div>
//   )
// }

// export default AddDeviceForm
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { AddEditDevice, updateDeviceVerifiedState } from '../appActions';
import cross_logo from '../Assets/Icons/cross.png';
import configVariables from '../util/constants';
import storageService from '../util/storageService';
import DeviceVerification from './DeviceVerification';
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
    background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',
    fontSize:'12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#303043',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#FB493F',
      color: 'white',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

function AddDeviceForm({ setCloseModal, business_det = [], mode, editObject }) {
  const { register, handleSubmit, setValue } = useForm({ defaultValues: editObject });
  const [projectOption, setProjectOption] = useState({});
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [formErrors, setFormErrors] = useState('');

  useEffect(() => {
    if (mode === 'edit') {
      let biz = getObjectById(business_det, editObject.business_id);
      setProjectOption(biz);
    }
    // eslint-disable-next-line
  }, []);

  const handleBusinessSelect = (selectedOption) => {
    if (selectedOption?.value !== 'null') {
      let biz = getObjectById(business_det, selectedOption.value);
      setProjectOption(biz);
      setValue('address', biz.address);
      setValue('email', biz.email);
      setValue('business_id', biz._id);
    }
  };

  const handleProjectSelect = (selectedOption) => {
    setValue('project_id', selectedOption?.value || 'null');
  };

  const getObjectById = (arr, id) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]._id === id) {
        return arr[i];
      }
    }
  };

  const onSubmit = (data) => {
    console.log('data',data)
    if (data.business_id !== 'null' && data.project_id !== 'null') {
      if (mode !== 'edit') {
        dispatch(updateDeviceVerifiedState(false));
      }
      data.created_by = storageService.get(configVariables.USER_ID);
      data.ids = mode === 'edit' ? [editObject._id] : state.device_det.map((device) => device._id);
      
      if (mode === 'edit' && data.project_id !== editObject.project_id) {
        data.old_project_id = editObject.project_id;
      }
      dispatch(AddEditDevice(data));
      setCloseModal(false);
    } else {
      setFormErrors('Select a business/project');
    }
  };

  const businessOptions = business_det.map((business) => ({
    value: business._id,
    label: business.name,
  }));

  const projectOptions = projectOption?.project_id?.map((project) => ({
    value: project._id,
    label: project.name,
  })) || [];

  return (
    <div className='modal'>
      <form action=''>
        <img onClick={() => setCloseModal(false)} className='form-cross-but' src={cross_logo} alt='' />
        {formErrors && <p>{formErrors}</p>}
        <label htmlFor='' className='form-label'>
          Select Business
          {mode === 'edit' ? (
            <Select
              value={{ value: projectOption?._id, label: projectOption?.name }}
              options={[ ...businessOptions]}
              styles={customStyles}
            />
          ) : (
            <Select
              options={[ ...businessOptions]}
              onChange={handleBusinessSelect}
              styles={customStyles}
            />
          )}
        </label>
        <label htmlFor='' className='form-label'>
          Select Project
          <Select
            options={[ ...projectOptions]}
            onChange={handleProjectSelect}
            defaultValue={
              mode === 'edit' && editObject?.project_id
                ? { value: editObject.project_id, label: getObjectById(projectOptions, editObject.project_id)?.label }
                : { value: 'null', label: 'Select' }
            }
            styles={customStyles}
          />
        </label>
        {mode !== 'edit' && Object.keys(projectOption).length > 0 && (
          <DeviceVerification isMulti setFormErrors={setFormErrors} biz_phone={projectOption.phone} />
        )}
        {(state.isDeviceVerified || mode === 'edit') && (
          <>
            <label className='form-label' htmlFor=''>
              Device Name
              <input type='text' {...register('name', { required: true, maxLength: 20 })} />
            </label>

            <div className='form-but-group'>
              <button className='form-but-1' onClick={handleSubmit(onSubmit)} type='button'>
                {mode === 'edit' ? 'Save' : 'Add'}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default AddDeviceForm;
